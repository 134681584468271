console.log("First Load", Date.now())
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
//import reportWebVitals from './reportWebVitals';
import {ApolloClient, ApolloProvider, createHttpLink, InMemoryCache} from "@apollo/client";

import '@shopify/polaris/build/esm/styles.css';
import {authenticatedFetch} from "@shopify/app-bridge-utils";
import {Provider} from "@shopify/app-bridge-react";
import createApp from "@shopify/app-bridge";
import {getLocation} from "@shopify/app-bridge/client/redirect";

const SHOPIFY_API_KEY = process.env.REACT_APP_SHOPIFY_API;

console.log("API KEY BEING USED: ", SHOPIFY_API_KEY)

async function startup() {
    console.log("654654654");

    const query = new URLSearchParams(window.location.search);

    if ((query.get("host") === null && query.get("session") === null) && query.get("hmac") !== null) {
        const redirectURL = `${window.location.protocol}//${window.location.host}/shopify/auth${getLocation().search}`;

        console.log("REDIRECTING TO", redirectURL);

        window.location.href = redirectURL;
        return
    } else {
        console.log("SETTING HOST", query.get("host"));

        localStorage.setItem('shopify-host', query.get("host"));
    }

    let shopifyApp;

    const shopifyConfig = {
        apiKey: SHOPIFY_API_KEY,
        host: localStorage.getItem("shopify-host"),
    };

    try {
        shopifyApp = createApp(shopifyConfig);
    } catch (e) {
        console.log("Could not init shopify client due to missing host, hopefully this is okay", e);
    }

    const httpLink = createHttpLink({
        uri: `${window.location.protocol}//${window.location.host}/gql/mutate`,
        fetch: authenticatedFetch(shopifyApp)
    });

    const client = new ApolloClient({
        link: httpLink,
        cache: new InMemoryCache(),
    });

    const withShopifyHost = <React.StrictMode>
        <Provider config={shopifyConfig}>
            <ApolloProvider client={client}>
                <App/>
            </ApolloProvider>
        </Provider>
    </React.StrictMode>;

    const withoutShopifyHost = <React.StrictMode>
        <ApolloProvider client={client}>
            <App/>
        </ApolloProvider>
    </React.StrictMode>;

    ReactDOM.render(shopifyConfig.host ? withShopifyHost : withoutShopifyHost,
        document.getElementById('root')
    );

    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    //reportWebVitals();
}

console.log("Starting Up!")
startup();
