import {Route, Switch, withRouter} from "react-router";
import {useRoutePropagation,} from "@shopify/app-bridge-react";
import React from "react";
// import NewDraftOrderAdjustment from "./NewDraftOrderAdjustment";
// import DraftOrderAdjustmentSelector from "./AdjustmentSelector";
// import DraftOrders from "./DraftOrders";
// import CartToDraftOrder from "./CartToDraftOrder";
// import DraftOrderToCart from "./DraftOrderToCart";
// import PostAuth from "./PostAuth";
// import AuthRedirect from "./AuthRedirect";
// import Dashboard from "./Dashboard";
const NewDraftOrderAdjustment = React.lazy(() => import( "./NewDraftOrderAdjustment"));
const DraftOrderAdjustmentSelector = React.lazy(() => import( "./AdjustmentSelector"));
const DraftOrders = React.lazy(() => import( "./DraftOrders"));
const CartToDraftOrder = React.lazy(() => import( "./CartToDraftOrder"));
const DraftOrderToCart = React.lazy(() => import( "./DraftOrderToCart"));
const PostAuth = React.lazy(() => import( "./PostAuth"));
const AuthRedirect = React.lazy(() => import( "./AuthRedirect"));
const Dashboard = React.lazy(() => import( "./Dashboard"));

import {NEW_CART_TO_DRAFT_PATH, EXISTING_CART_TO_DRAFT_PATH} from "./util";

const LoadingScreen = () => {
  return <div>Loading</div>;
}

function AppRoutes(props) {
  // eslint-disable-next-line react/prop-types
  const {location} = props;

  useRoutePropagation(location);

  return (
    <Switch>
      <Route path="/shopify/callback">
        <React.Suspense fallback={LoadingScreen}>
          <PostAuth/>
        </React.Suspense>
      </Route>
      <Route path="/newDraftOrderAdjustment">
        <React.Suspense fallback={LoadingScreen}>
          <NewDraftOrderAdjustment/>
        </React.Suspense>
      </Route>
      <Route path="/draftOrderAdjustment">
        <React.Suspense fallback={LoadingScreen}>
          <NewDraftOrderAdjustment/>
        </React.Suspense>
      </Route>
      <Route path="/draftOrders">
        <React.Suspense fallback={LoadingScreen}>
          <DraftOrders/>
        </React.Suspense>
      </Route>
      <Route path={`${NEW_CART_TO_DRAFT_PATH}`}>
        <React.Suspense fallback={LoadingScreen}>
          <CartToDraftOrder/>
        </React.Suspense>
      </Route>
      <Route path={`${EXISTING_CART_TO_DRAFT_PATH}`}>
        <React.Suspense fallback={LoadingScreen}>
          <CartToDraftOrder/>
        </React.Suspense>
      </Route>
      <Route path="/draftOrderToCart">
        <React.Suspense fallback={LoadingScreen}>
          <DraftOrderToCart/>
        </React.Suspense>
      </Route>
      <Route path="/adjustmentSelector">
        <React.Suspense fallback={LoadingScreen}>
          <DraftOrderAdjustmentSelector/>
        </React.Suspense>
      </Route>
      <Route path="/">
        <React.Suspense fallback={LoadingScreen}>
          <AuthRedirect/>
        </React.Suspense> 
      </Route>
    </Switch>
  );
}

export default withRouter(AppRoutes);
