import { withRouter } from "react-router";
import { useClientRouting } from "@shopify/app-bridge-react";

function Router(props) {
  const { history } = props;

  useClientRouting(history);

  return null;
}

export default withRouter(Router);
